import React from "react";
import RestoreIcon from '@material-ui/icons/Restore';
export function CaseVersionButton(props) {
    return React.createElement("button", { type: "button", className: "btn btn-secondary btn-sm", style: {
            border: '1px solid #40A5F4',
            color: '#40A5F4',
            width: 130,
            maxHeight: 34,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            fontWeight: 900,
        }, onClick: function () { return props.onClick(); } },
        React.createElement(RestoreIcon, null),
        "\u00A0",
        props.title);
}
