import React, { Component, memo } from "react";
import PropTypes from "prop-types";
import { connect, Provider } from "react-redux";
import store from "../../../../store";
import { formValueSelector, getFormValues } from "redux-form";
import CaseVictimAccident from "./CaseVictimAccident"
import CaseMetadata from "./CaseMetadata";
import CaseData from "../case_data_form/CaseData";
import CaseFooter from "./CaseFooter";
import { deleteCase, getCase, getCaseVariants, updateCase } from "../../../../actions/cases";
import SideMenu from "../../../layout/SideMenu";
import SmallScreens from "./SmallScreens";
import DisplayWarningMsg from "./DisplayWarningMsg";
import CaseTitle from "./CaseTitle";
import { LoadingComponent } from "../../../common/LoadingComponent";
import { getTeamsAndMembers } from "../../../../actions/teams";
import { CaseVersionButton } from "./CaseVersionButton";
import { CaseVersionDialog } from "./CaseVersionDialog";
import { CaseConversationButton } from "../conversation/CaseConversationButton";
import { CaseConversationDialog } from "../conversation/CaseConversationDialog";


export class Case extends Component {
    static propTypes = {
      getCase: PropTypes.func.isRequired,
      getCaseVariants: PropTypes.func.isRequired,
      deleteCase: PropTypes.func.isRequired,
      case: PropTypes.object,
      variantParent: PropTypes.object,
      caseVariants: PropTypes.array,
      victim: PropTypes.object,
      getTeamsAndMembers: PropTypes.func.isRequired,
      teams: PropTypes.array.isRequired,
      orgId: PropTypes.string,
      caseValues: PropTypes.object,
      auth: PropTypes.object,
    };


    state = {
      saveCounter: [],
      saveCounterTitle: [],
      isSaving: false,
      showProvisionDialog: false,
      showTicketDialog: false,
      loaded: false,
    }

    processNextSave = () => {
      const { saveCounter, saveCounterTitle } = this.state;

      if (saveCounter.length === 0 && saveCounterTitle.length === 0) {
        return
      }

      this.setState({ isSaving: true }, () => {
        if (saveCounterTitle.length > 0) {
          this.caseTitleSubmit(saveCounterTitle[0]).finally(() => {
            this.setState(prevState => ({
              isSaving: false,
              saveCounterTitle: prevState.saveCounterTitle.slice(1),
            }), this.processNextSave)
          })
          return
        }
        if (saveCounter.length > 0) {
          this.caseDataSubmit(saveCounter[0]).finally(() => {
            this.setState(prevState => ({
              isSaving: false,
              saveCounter: prevState.saveCounter.slice(1),
            }), this.processNextSave)
          })
          return
        }
      })
    };

    enqueueSave = (values) => {
      if (this.props.case.variant_locked) {
        return
      }
      try {
        this.setState(prevState => ({
          saveCounter: [...prevState.saveCounter, values],
        }), () => {
          if (!this.state.isSaving) {
            this.processNextSave();
          }
        })
      } catch (e) {
        console.error(e)
      }
    }

    componentDidMount() {
      if (this.state.loaded) {
        return
      }
      this.setState({ loaded: true })

      this.props.getTeamsAndMembers();
      this.props.getCase(this.props.params.caseId);

      // parse query params
      const queryParams = new URLSearchParams(window.location.search);
      const conversation_open = queryParams.get('conversation_open');
      if (conversation_open === 'true') {
        this.setState({ showTicketDialog: true });
      }
    }

    caseDataSubmit = values => {
      const caseData = values;

      let c = {
        id: this.props.case.id,
        title: this.props.caseTitle,
        version: this.props.case.version,
        json_data: {
          ...this.props.case.json_data,
          caseData,
          victim: { ...this.props.case.json_data.victim, ...this.props.victimData },
        },
      };

      return this.props.updateCase(c);
    };


    caseTitleSubmit = (title) => {
      let c = {
        id: this.props.case.id,
        version: this.props.case.version,
        title: title,
      };

      return this.props.updateCase(c);
    };

    enqueueSaveTitle = () => {
      if (this.props.case.variant_locked) {
        return
      }
      try {
        this.setState(prevState => ({
          saveCounterTitle: [...prevState.saveCounterTitle, this.props.caseTitle],
        }), () => {
          if (!this.state.isSaving) {
            this.processNextSave();
          }
        })
      } catch (e) {
        console.error(e)
      }
    }

    render() {
      const { date_created, date_modified } = this.props.case || {};
      const dateCascadeReverse = new Date("2023-01-20")
      const dateCreated = new Date(date_created)


      let isTeamMember = false

      const users = {}
      if (this.props.teams && this.props.teams.length > 0) {
        isTeamMember = true
        this.props.teams.forEach(team => {
          team.members.forEach(member => {
            users[member.user_id] = { email: member.user_email, name: member.user_name };
          });
        });
      }


      if (this.props.auth && this.props.auth.user) {
        users[this.props.auth.user.id] = { email: this.props.auth.user.email, name: this.props.auth.user.name };
      }

      const getNameByUserId = userId => users[userId] ? users[userId].name || users[userId].email || "Utilisateur inconnu" : "Utilisateur inconnu";

      return (
        <>
          {dateCreated < dateCascadeReverse && <DisplayWarningMsg/>}
          <div className="mobile"><SmallScreens/></div>
          <div className="desktop">
            {this.props.isLoading && "Chargement du dossier en cours..."}

            <Provider store={store}>
              {Object.keys(this.props.case).length > 0 ?
                <>
                  <div className="wrapper">
                    {this.props.orgId ?
                      <CaseConversationDialog open={this.state.showTicketDialog}
                        caseId={this.props.case.id}
                        orgId={this.props.orgId}
                        caseIdUnique={this.props.case.id_unique}
                        onClose={() => this.setState({ showTicketDialog: false })}
                        caseName={this.props.case.title}
                      /> : null}
                    <CaseVersionDialog open={this.state.showProvisionDialog}
                      caseVariants={this.props.caseVariants}
                      case={this.props.case}
                      hasUnsavedChanges={this.props.hasUnsavedChanges}
                      onClose={() => this.setState({ showProvisionDialog: false })}
                    />
                    <SideMenu victim={this.props.victim}/>
                    <div className="container-fluid">
                      <div className="sticky-top sticky-offset">
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <div>
                            <CaseTitle onSubmit={this.enqueueSaveTitle}
                              title={this.props.case.title}
                            />
                            <CaseMetadata date_created={date_created}
                              date_modified={date_modified}
                              created_by={getNameByUserId(this.props.case.created_by_id)}
                              modified_by={getNameByUserId(this.props.case.modified_by_id)}
                              is_team_member={isTeamMember}
                              variantTitle={this.props.caseVariants && this.props.caseVariants.length > 1 && this.props.case.variant_title}
                            />
                          </div>
                          <div style={{ alignContent: "center", columnGap: 15 }}>
                            {this.props.orgId ? <CaseConversationButton
                              onClick={() =>
                                this.setState({ showTicketDialog: true })
                              }
                            /> : null}
                            <CaseVersionButton
                              title="Versions"
                              onClick={() => this.setState({ showProvisionDialog: true })}
                            />
                          </div>
                        </div>

                        <CaseVictimAccident/>
                      </div>
                      <CaseData onSubmit={this.enqueueSave} victim={this.props.victim}/>
                    </div>
                  </div>
                  <CaseFooter caseId={this.props.case.id}/>
                </>
                : <LoadingComponent/>
              }
            </Provider>
          </div>
        </>

      );
    }
}

const victimSelector = formValueSelector("victim");
const titleSelector = formValueSelector("caseTitleForm");
const dataSelector = formValueSelector("caseDataForm");

const mapStateToProps = state => {
  let victim = {};
  let victimData = {};
  let caseTitle = "";


  let c = {};
  let caseValues = {};
  let indirectVictims = [];
  let hasUnsavedChanges = false

  if (state.cases && state.cases.case) {
    c = state.cases.case;
    if (state.cases.case && state.cases.case.title) {
      caseTitle = titleSelector(state, "title")
    }
    if (state.cases && state.cases.case && state.cases.case.json_data) {
      victimData.last_name = victimSelector(state, "last_name");
      victimData.first_name = victimSelector(state, "first_name");

      victimData.rateOfCompensationEntitlement = victimSelector(
        state,
        "rateOfCompensationEntitlement",
      );
      victimData.disableCascade = victimSelector(state, "disableCascade");
      victimData.consolidationDate = victimSelector(state, "consolidationDate");
      victimData.deathDate = victimSelector(state, "deathDate");
      victimData.date = victimSelector(state, "date");
      victimData.accidentType = victimSelector(state, "accidentType");
      victimData.birthDate = victimSelector(state, "birth_date");
      victimData.gender = victimData.victimGender = victimSelector(state, "victimGender"); // to compensate for bad choice of variable names in direct victim. To be fixed!!!

      // Clean up undefined fields
      const cleanVictimData = Object.fromEntries(
        Object.entries(victimData).filter(([_, value]) => value !== undefined),
      );
      victimData = cleanVictimData
      // Assign cleaned data to victim
      victim = cleanVictimData;

      caseValues = getFormValues("caseDataForm")(state);
      if (caseValues) {
        indirectVictims = caseValues.indirectVictims

        const caseData = { ...state.cases.case.json_data.caseData }
        // ignore meta field
        delete caseData._meta

        const formValues = { ...caseValues }
        // ignore meta field
        delete formValues._meta

        const caseDataStr = JSON.stringify(caseData)
        const formValuesStr = JSON.stringify(formValues)

        hasUnsavedChanges = caseDataStr !== formValuesStr
        // console.log({caseData, formValues, hasUnsavedChanges})
      }
    }
  }


  let orgId = null

  for (const team of state.teams.teams) {
    if (team.organization && !orgId) {
      orgId = team.organization
    }
  }

  return {
    caseValues,
    victim,
    victimData,
    caseTitle,
    variantParent: state.cases.variantParent,
    teams: state.teams.teams,
    orgId,
    case: c,
    caseVariants: state.cases.caseVariants,
    indirectVictims,
    auth: state.auth,
    hasUnsavedChanges,
  };
};

export default connect(mapStateToProps, {
  getCase,
  getCaseVariants,
  deleteCase,
  updateCase,
  getTeamsAndMembers,
})(memo(Case));
