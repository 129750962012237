import { getIndirectVictimFullName } from "../../../utils.js";
import { interval } from "../../../utils.js";
import { tppTotal } from "../../calculations.js";

export const getYearlyIncome = (line) => {
  let yearlyIncome = 0;

  if (line && line.refAmount) {
    yearlyIncome = parseFloat(line.refAmount.toString().replace(",", ".").replace(/\s/g, ""))
    if (line.discounting && line.discounting.indexValue) yearlyIncome *= line.discounting.indexValue;
    let durationType = line.durationType;
    if (!durationType) durationType = "days";

    switch (durationType) {
      case "days":
        yearlyIncome *= 365;
        break;
      case "weeks":
        yearlyIncome *= 52;
        break;
      case "months":
        yearlyIncome *= 12;
        break;
      default:
        break;
    }
  }

  yearlyIncome = Math.round(yearlyIncome * 100) / 100;

  return yearlyIncome;
}

export const getIncomeArrayTotal = (incomeArray) => {
  let incomeArrayTotal = 0;

  incomeArray.map((line) => incomeArrayTotal += getYearlyIncome(line))

  return incomeArrayTotal;
}


export const getSelfConsumptionAmount = (householdIncome) => {
  let selfConsumptionAmount = 0;

  let originalIncomeAmount = 0;
  if (householdIncome.originalIncome)
    originalIncomeAmount = getIncomeArrayTotal(householdIncome.originalIncome);
  if (householdIncome.selfConsumption && householdIncome.selfConsumption.percentage) {
    let percentage = parseFloat(householdIncome.selfConsumption.percentage.toString().replace(",", ".").replace(/\s/g, ""));
    selfConsumptionAmount = Math.round(originalIncomeAmount * percentage) / 100;
  }

  return selfConsumptionAmount;
}


export const getHouseholdIncomeLoss = (householdIncomeLine) => {
  let householdIncomeLoss = 0;

  if (householdIncomeLine.originalIncome)
    householdIncomeLoss = getIncomeArrayTotal(householdIncomeLine.originalIncome) - getSelfConsumptionAmount(householdIncomeLine);
  if (householdIncomeLine.residualIncome)
    householdIncomeLoss -= getIncomeArrayTotal(householdIncomeLine.residualIncome);

  householdIncomeLoss = Math.max(0, householdIncomeLoss)

  return householdIncomeLoss;
}


export const getVictAmount = (line, householdIncomeList) => {
  let victAmount = 0;

  const selectedHouseholdIncome = householdIncomeList.filter((householdIncome) => householdIncome.incomeId === line.householdLoss);
  if (line.victStatus && line.victStatus === "mainVict") line.indirectVictShare = "100";
  if (selectedHouseholdIncome.length && line.indirectVictShare)
    victAmount = Math.round(parseFloat(line.indirectVictShare.toString().replace(",", ".").replace(/\s/g, "")) * selectedHouseholdIncome[0].amount) / 100;

  return victAmount;
}


export const getPastDurationStr = (startDate, endDate) => {
  let pastDurationStr ="";

  if (startDate && endDate) {
    const duration = interval(startDate, endDate, "years");
    pastDurationStr = `(${duration.toLocaleString("fr-FR", { maximumFractionDigits: 3 })} an`;
    if (duration >= 2) pastDurationStr += "s";
    pastDurationStr += ")";
  }

  return pastDurationStr;
}


export const getPastAmount = (line, householdIncomesList) => {
  let pastAmount = 0;

  if (line.pastStartDate && line.pastEndDate && line.indirectVictShare) {
    const duration = interval(line.pastStartDate, line.pastEndDate, "years");
    pastAmount = duration * getVictAmount(line, householdIncomesList);
    pastAmount = Math.round(pastAmount * 100) / 100;
  }

  return pastAmount;
}


export const getFutureAmount = (line, householdIncomesList) => {
  let futureAmount = 0;

  if (line.capitalisationPER) {
    const PERNum = parseFloat(line.capitalisationPER.toString().replace(" ","").replace(",","."));
    const lineAmount = getVictAmount(line, householdIncomesList);
    futureAmount = PERNum * lineAmount;
    futureAmount = Math.round(futureAmount * 100) / 100;
  }

  return futureAmount;
}



export const getSideVictimsTotalAmount = (householdIncomesList, incomeId, indirectVictims) => {
  let sideVictimsTotalAmount = 0;

  indirectVictims.forEach((victim) => {

    victim.caseData.PRev0 && victim.caseData.PRev0.forEach((line) => {
      if (line.householdLoss === incomeId ) sideVictimsTotalAmount += getPastAmount(line, householdIncomesList) + getFutureAmount(line, householdIncomesList);
    },
    )
  })

  return sideVictimsTotalAmount;
}


export const getTotalAmount = (line, householdIncomesList, indirectVictims) => {
  let totalAmount = getPastAmount(line, householdIncomesList) + getFutureAmount(line, householdIncomesList);

  if (line.victStatus && line.victStatus ==="mainVict")
    totalAmount = Math.max(0, totalAmount*2 - getSideVictimsTotalAmount(householdIncomesList, line.householdLoss, indirectVictims));

  return totalAmount;
}



export const getVictimClaim = (line, householdIncomesList, rateOfCompensationEntitlement, indirectVictims) => {
  let lineResponsiblePartyAmount = getPRevLineRespPartyAmount(line, householdIncomesList, rateOfCompensationEntitlement, indirectVictims)
  let victimClaim = Math.min(lineResponsiblePartyAmount, Math.max(0, getTotalAmount(line, householdIncomesList, indirectVictims) - tppTotal(line.tpp)));

  return victimClaim;
}


export const getTppRecourse = (line, householdIncomesList, rateOfCompensationEntitlement, indirectVictims) => {
  let tppRecourse = Math.max(0, getPRevLineRespPartyAmount(line, householdIncomesList, rateOfCompensationEntitlement, indirectVictims) - getVictimClaim(line, householdIncomesList, rateOfCompensationEntitlement, indirectVictims));

  tppRecourse = Math.min(tppRecourse, tppTotal(line.tpp));

  return tppRecourse;
}


export const getPRevLineRespPartyAmount = (line, householdIncomesList, rateOfCompensationEntitlement, indirectVictims) => {
  let respPartyAmount = getTotalAmount(line, householdIncomesList, indirectVictims);

  respPartyAmount = Math.round(respPartyAmount * rateOfCompensationEntitlement) / 100;

  return respPartyAmount;
}


export const getHouseholdIncomesList = (householdIncomes) => {
  let householdIncomesList = [];

  if (householdIncomes) {
    householdIncomes.forEach((line, i) => {
      let lossAmount = 0;
      if (line.householdLossAmount)
        lossAmount = parseFloat(line.householdLossAmount.toString().replace(",", ".").replace(/\s/g, ""));
      let householdIncome = { amount : lossAmount, label: "Préjudice du foyer n° "+ (i + 1), incomeId: line.incomeId };
      if (line.incomeLabel && line.incomeLabel.trim().length > 0 ) householdIncome.label = line.incomeLabel.trim();
      householdIncomesList.push(householdIncome);
    })
  }

  return householdIncomesList;
}


export const getHouseholdIncomesAllocation = (householdIncomesList, indirectVictims, incomeLossCalcMethod = "classic") => {
  let householdIncomesAllocation = {};

  if (householdIncomesList) {
    householdIncomesList.forEach((householdIncome) => {
      let householdIncomePerVict = {};
      if (indirectVictims) {
        indirectVictims.forEach((victim, victimId) => {
          if (victim.caseData && victim.caseData.PRev0) {
            let excludeVict = false;
            if (incomeLossCalcMethod === "redistribution" && victim.caseData.PRev0[0] && victim.caseData.PRev0[0].victStatus === "mainVict") excludeVict = true;
            !excludeVict && victim.caseData.PRev0.forEach((incomeLoss) => {
              if (incomeLoss.householdLoss === householdIncome.incomeId && incomeLoss.indirectVictShare) {
                let victimShare = parseFloat(incomeLoss.indirectVictShare.toString().replace(",", ".").replace(/\s/g, ""))
                if (!householdIncomePerVict[victimId]) householdIncomePerVict[victimId] = victimShare;
                else householdIncomePerVict[victimId] += victimShare;
              }
            })
          }
        })
      }
      householdIncomesAllocation[householdIncome.incomeId] = householdIncomePerVict;
    })
  }

  return householdIncomesAllocation;
}


export const getHouseholdIncomesAllocationDetails = (householdIncomesAllocation, indirectVictims, householdIncomesList) => {
  let householdIncomesAllocationDetails = {};

  Object.entries(householdIncomesAllocation).forEach(([incomeId, allocation]) => {
    let allocationDetails = { incomeLabel: "", totalUsed: 0, victims: [] };
    const currentHouseholdIncome = householdIncomesList.filter((householdIncome) => householdIncome.incomeId === incomeId);
    if (currentHouseholdIncome.length && currentHouseholdIncome[0])
      allocationDetails.incomeLabel = currentHouseholdIncome[0].label;
    Object.keys(allocation).forEach(victim => {
      allocationDetails.totalUsed += allocation[victim]
      let victimDetails = { name: getIndirectVictimFullName(indirectVictims[victim].victim, victim, 0, true),
        share: allocation[victim] };
      allocationDetails.victims.push(victimDetails)
    })
    householdIncomesAllocationDetails[incomeId] = allocationDetails;
  });

  return householdIncomesAllocationDetails;
}


export const getAllocationDetailsStr = (allocationDetails, incomeId, withPercents = true) => {
  let allocationDetailsStr = "";

  if (allocationDetails && allocationDetails[incomeId]) {
    const incomeDetails = allocationDetails[incomeId];
    allocationDetailsStr = `Ce préjudice est actuellement alloué à hauteur de ${Intl.NumberFormat("fr-FR", { style: "percent", maximumFractionDigits: 2 }).format(incomeDetails.totalUsed/100)}`;
    if (incomeDetails.victims && incomeDetails.victims.length > 0)
      allocationDetailsStr += " au profit de " + getVictimListStr(incomeDetails.victims, withPercents);
    allocationDetailsStr += ".";
  }

  return allocationDetailsStr;
}


export const getVictimListStr = (victims, withPercents) => {
  let victimList = "";

  if (victims && victims.length == 1) victimList = victims[0].name;
  else if (victims && victims.length >= 1) {
    victims.forEach((victim, i) => {
      let victim_str = victim.name
      if (withPercents) victim_str+= ` (${Intl.NumberFormat("fr-FR", { style: "percent", maximumFractionDigits: 2 }).format(victim.share/100)})`
      if (i == victims.length - 2) victim_str += " et ";
      else if (i < victims.length - 2) victim_str += ", ";
      victimList += victim_str;
    })
  }

  return victimList;
}


export const getPRevEntryTotal = (householdIncomesList, victim, indirectVictims, victimId) => {
  let pRevEntryTotal = {
    hasTPP: false,
    nbLines: 0,
    damageTotal: 0, //
    victimTotal: 0, //
    annuityTotal: 0, // to be implemented
    paidTPPTotal: 0, //
    afterTPPTotal: 0, //
    annuityDetails: {}, // to be implemented
    hasNonAffected: false, // to be implemented
    annuityCapTotal: 0, // to be implemented
    futureTppSurplus: 0, // not necessary
    responsiblePartyTotal: 0, //
    thirdPartyPayersTotal: 0,
    absorbedFutureTppSurplus: 0, // not necessary for this entry
    pastFutureSummary: null, // not necessary for this entry
  }

  let PRevValues = [];
  if (indirectVictims[victimId] && indirectVictims[victimId].caseData && indirectVictims[victimId].caseData.PRev0)
    PRevValues = indirectVictims[victimId].caseData.PRev0;
  pRevEntryTotal.nbLines = PRevValues.length;

  const rateOfCompensationEntitlement = parseInt(victim.rateOfCompensationEntitlement) || 100;

  PRevValues.forEach(lineValues => {
    let lineTppTotal = 0;
    const lineDamage = Math.round(getTotalAmount(lineValues, householdIncomesList, indirectVictims) * 100) / 100;
    const lineResponsiblePartyAmount = Math.round(getPRevLineRespPartyAmount(lineValues, householdIncomesList, rateOfCompensationEntitlement, indirectVictims) * 100) / 100;
    pRevEntryTotal.damageTotal += lineDamage;
    pRevEntryTotal.responsiblePartyTotal += lineResponsiblePartyAmount;
    if (lineValues.tpp && lineValues.tpp.length > 0) {
      pRevEntryTotal.hasTPP = true;
      lineTppTotal = tppTotal(lineValues.tpp);
      pRevEntryTotal.paidTPPTotal += lineTppTotal;
    }
    const lineAfterTPPTotal = lineDamage - lineTppTotal;
    if (lineAfterTPPTotal < 0) pRevEntryTotal.afterTPPTotal += lineAfterTPPTotal;
    const lineVictimClaim = Math.min(lineResponsiblePartyAmount, Math.max(0, lineAfterTPPTotal));
    pRevEntryTotal.victimTotal += lineVictimClaim;
    const lineTppRecourse = Math.min(lineTppTotal, Math.max(0, lineResponsiblePartyAmount - lineVictimClaim));
    pRevEntryTotal.thirdPartyPayersTotal += lineTppRecourse;
  })

  pRevEntryTotal.victimTotal = pRevEntryTotal.afterTPPTotal = pRevEntryTotal.damageTotal - pRevEntryTotal.paidTPPTotal;
  pRevEntryTotal.victimTotal = Math.min(pRevEntryTotal.victimTotal, pRevEntryTotal.responsiblePartyTotal);

  return pRevEntryTotal;
}


export const getMainIndirectVictId = (indirectVictims) => {
  let mainIndirectVict = null;

  indirectVictims.forEach((vict, i) => {
    if (vict.caseData.PRev0 && vict.caseData.PRev0) {
      vict.caseData.PRev0.forEach((line) => {if (line.victStatus === "mainVict") mainIndirectVict = i;})
    }
  });

  return mainIndirectVict;
}
