var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useState } from "react";
import { ClientSideSuspense, useInboxNotificationThread } from "@liveblocks/react/suspense";
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { Box, ClickAwayListener, Popper, Tab } from "@material-ui/core";
import { ErrorBoundary } from "../cases/physical_injury/conversation/LiveblockErrorBoundry";
import Tabs from "@material-ui/core/Tabs";
import { filterUnreadNotifications } from "../cases/physical_injury/conversation/liveblocksUtil";
import { useLiveblocksNotifications } from "./LiveblocksNotificationsProvider";
function ExtractCaseIdFromRoomId(roomId) {
    if (!roomId) {
        return '';
    }
    return roomId.split(':')[2];
}
function CaseNotifications(props) {
    var _this = this;
    var readNotifications = function (e) { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            e.preventDefault();
            window.location.href = e.currentTarget.href;
            return [2 /*return*/];
        });
    }); };
    var unreadNotifications = filterUnreadNotifications(props.notifications);
    return React.createElement("div", { style: {
            padding: '16px 16px',
            background: 'rgb(245, 245, 245)',
            fontSize: 14,
            display: "flex",
        } },
        React.createElement(NotificationCaseLinkRenderer, { notifications: props.notifications, caseId: props.caseId, case: props.case, onLinkClick: readNotifications, hasUnreadNotifications: unreadNotifications.length }),
        React.createElement(Badge, { badgeContent: unreadNotifications.length, color: "error" },
            React.createElement(NotificationsIcon, null)));
}
function NotificationTitle(_a) {
    var notification = _a.notification, onTitleFound = _a.onTitleFound, onCaseIdFound = _a.onCaseIdFound;
    var thread = useInboxNotificationThread(notification.id);
    useEffect(function () {
        if (thread.metadata.caseName) {
            onTitleFound(thread.metadata.caseName);
        }
        if (thread.metadata.caseId) {
            onCaseIdFound(thread.metadata.caseId);
        }
    }, [thread.metadata.caseName, onTitleFound, thread.metadata.caseId, onCaseIdFound]);
    return null;
}
function NotificationCaseLinkRenderer(props) {
    var _a = useState(""), caseTitle = _a[0], setCaseTitle = _a[1];
    var _b = useState(props.caseId), caseIdRender = _b[0], setCaseIdRender = _b[1];
    useEffect(function () {
        if (props.case) {
            setCaseTitle(props.case.title);
        }
    }, [props.case]);
    var handleTitleFound = function (title) {
        if (!props.case) {
            setCaseTitle(title);
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("a", { style: { flexGrow: 1, fontWeight: props.hasUnreadNotifications ? 'bold' : 'normal' }, href: "/dossier/".concat(caseIdRender, "?conversation_open=true"), onClick: props.onLinkClick }, caseTitle),
        props.notifications.sort(function (a, b) {
            // return new Date(b.notifiedAt).getTime() - new Date(a.notifiedAt).getTime()
            return new Date(a.notifiedAt).getTime() - new Date(b.notifiedAt).getTime();
        }).map(function (n) { return (React.createElement(NotificationTitle, { key: n.id, notification: n, onTitleFound: handleTitleFound, onCaseIdFound: setCaseIdRender })); })));
}
function NotificationsInternal(props) {
    var inboxNotifications = props.notifications, cases = props.cases;
    var notificationsByCaseId = (inboxNotifications === null || inboxNotifications === void 0 ? void 0 : inboxNotifications.reduce(function (acc, n) {
        var caseId = ExtractCaseIdFromRoomId(n.roomId);
        if (!acc[caseId]) {
            acc[caseId] = [];
        }
        acc[caseId].push(n);
        return acc;
    }, {})) || {};
    return React.createElement("div", { style: { padding: 16, display: "flex", flexDirection: "column", gap: 8 } }, Object
        .entries(notificationsByCaseId)
        .map(function (_a) {
        var caseId = _a[0], notifications = _a[1];
        return React.createElement(CaseNotifications, { key: caseId, caseId: caseId, notifications: notifications, case: cases.find(function (c) { return String(c.id) === caseId || String(c.id_unique) === caseId; }) });
    }));
}
function PopoverHeader() {
    return React.createElement("h2", { className: "pl-3 pt-2" }, "Centre de notifications");
}
function NotificationBellInside(props) {
    var _a = React.useState(0), value = _a[0], setValue = _a[1];
    var cases = props.cases, notifications = props.notifications;
    var handleChange = function (event, newValue) {
        setValue(newValue);
    };
    return React.createElement(React.Fragment, null,
        React.createElement(Box, { style: { borderBottom: 1, borderColor: 'divider', width: '100%' } },
            React.createElement(Tabs, { value: value, style: { width: '100%' }, onChange: handleChange, "aria-label": "basic tabs example" },
                React.createElement(Tab, { style: { flexGrow: 1 }, label: "Dossiers" }),
                React.createElement(Tab, __assign({ style: { flexGrow: 1 }, label: "Infos Quantum" }, a11yProps(1))))),
        value === 0 && React.createElement(ErrorBoundary, null,
            React.createElement(ClientSideSuspense, { fallback: React.createElement("div", null, "Chargement en cours...") },
                React.createElement(NotificationsInternal, { notifications: notifications, cases: cases }))),
        value === 1 && React.createElement("div", null, "Communications de Quantum"));
}
function NotificationBell(props) {
    var cases = props.cases;
    var _a = useState(null), anchorEl = _a[0], setAnchorEl = _a[1];
    var _b = useLiveblocksNotifications(), unreadNotifications = _b.unreadNotifications, notifications = _b.notifications;
    var handleClick = function (event) {
        event.stopPropagation();
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };
    var handleClose = function () {
        setAnchorEl(null);
    };
    return (React.createElement(ClickAwayListener, { onClickAway: handleClose },
        React.createElement("div", null,
            React.createElement(IconButton, { onClick: handleClick, style: { color: 'white', marginRight: 16 } },
                React.createElement(Badge, { badgeContent: (unreadNotifications === null || unreadNotifications === void 0 ? void 0 : unreadNotifications.length) || 0, color: "error" },
                    React.createElement(NotificationsIcon, null))),
            React.createElement(Popper, { open: Boolean(anchorEl), anchorEl: anchorEl, style: { zIndex: 1100, background: 'white', borderRadius: 12, border: '1px solid gray' } },
                React.createElement("div", { style: { minHeight: 300, maxHeight: 500, width: 500 } },
                    React.createElement(PopoverHeader, null),
                    React.createElement(NotificationBellInside, { notifications: notifications, cases: cases }))))));
}
function a11yProps(index) {
    return {
        id: "simple-tab-".concat(index),
        'aria-controls': "simple-tabpanel-".concat(index),
    };
}
export function NotificationsLiveblocks(_a) {
    var cases = _a.cases;
    return React.createElement(NotificationBell, { cases: cases });
}
