import React from "react";
import { connect } from "react-redux";
import { logout } from "../../../../actions/auth";
import { LiveblocksProviderImpl } from "./LiveblocksProviderImpl";
function LiveblocksProviderWithAuthInternal(_a) {
    var children = _a.children, auth = _a.auth;
    if (!auth) {
        return children;
    }
    return React.createElement(LiveblocksProviderImpl, { auth: auth }, children);
}
var mapStateToProps = function (state) { return ({
    auth: state.auth,
}); };
export var LiveblocksProviderWithAuth = connect(mapStateToProps, { logout: logout })(LiveblocksProviderWithAuthInternal);
