var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { localTheme, palette, useStyles } from "../../interests/theme";
import { ClientSideSuspense, RoomProvider, useCreateThread, useMarkInboxNotificationAsRead, useRoomNotificationSettings, useThreads, } from "@liveblocks/react/suspense";
import { ErrorBoundary } from "./LiveblockErrorBoundry";
import { Composer as ComposerMain, Thread } from "@liveblocks/react-ui";
import { Resizable } from "react-resizable";
import "react-resizable/css/styles.css";
import { ThemeProvider } from '@material-ui/core/styles';
import AddCircleOutline from "@material-ui/icons/AddCircleOutline";
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import Close from '@material-ui/icons/Close';
import Send from '@material-ui/icons/Send';
import { Button, CircularProgress, IconButton, TextField, Typography } from "@material-ui/core";
import { filterUnreadNotifications } from "./liveblocksUtil";
import { useLiveblocksNotifications } from "../../../layout/LiveblocksNotificationsProvider";
// Custom Composer component that can be reused
function CustomComposer(_a) {
    var onComposerSubmit = _a.onComposerSubmit, _b = _a.isSubmitDisabled, isSubmitDisabled = _b === void 0 ? false : _b;
    return (React.createElement("div", { style: { margin: '8px 0', display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' } },
        React.createElement("div", { style: { flex: 1, position: 'relative' } },
            React.createElement("div", { style: {
                    position: 'relative',
                    height: '37.63px',
                    border: '1px solid #7BA3CC',
                    borderRadius: '4px',
                    backgroundColor: '#FFFFFF',
                    overflow: 'hidden',
                    transition: 'height 0.2s ease',
                }, className: "custom-composer-wrapper", ref: function (el) {
                    if (el) {
                        // Add a MutationObserver to detect content changes
                        var observer = new MutationObserver(function () {
                            var editor = el.querySelector('.lb-composer-editor');
                            if (editor && editor.textContent && editor.textContent.trim() !== '') {
                                // If there's content, expand the wrapper
                                el.style.height = 'auto';
                                el.style.maxHeight = '200px';
                                el.style.overflowY = 'auto';
                            }
                            else {
                                // If there's no content, reset to default height
                                el.style.height = '37.63px';
                                el.style.maxHeight = '37.63px';
                                el.style.overflowY = 'hidden';
                            }
                        });
                        // Start observing the wrapper for content changes
                        observer.observe(el, {
                            childList: true,
                            subtree: true,
                            characterData: true,
                        });
                    }
                } },
                React.createElement(ComposerMain, { showAttachments: false, onComposerSubmit: onComposerSubmit, className: "custom-composer" }))),
        React.createElement(IconButton, { size: "small", style: {
                backgroundColor: '#F0D1EA',
                color: 'rgba(158, 51, 134, 0.5)',
                borderRadius: '4px',
                cursor: isSubmitDisabled ? 'not-allowed' : 'pointer',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexShrink: 0,
                padding: '6px',
                width: '32px',
                height: '32px',
                opacity: isSubmitDisabled ? 0.5 : 1,
            }, disabled: isSubmitDisabled, onClick: function (e) {
                if (!isSubmitDisabled) {
                    // Trigger a click on the composer's send button
                    var sendButton = document.querySelector('.custom-composer .lb-button[data-variant="primary"]');
                    if (sendButton) {
                        sendButton.click();
                    }
                }
            } },
            React.createElement(Send, { fontSize: "small" }))));
}
function Room(_a) {
    var children = _a.children, roomId = _a.roomId;
    return (React.createElement(RoomProvider, { id: roomId },
        React.createElement(ErrorBoundary, null,
            React.createElement(ClientSideSuspense, { fallback: React.createElement("div", { style: {
                        width: "100%",
                        height: "100%",
                        background: "rgba(0, 0, 0, 0.3)",
                        zIndex: 99999,
                        top: "0",
                        right: "0",
                        bottom: "0",
                        left: "0",
                        position: "fixed",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    } },
                    React.createElement(CircularProgress, { size: 60 })) }, children))));
}
function TicketCreateThread(props) {
    var _this = this;
    var createThread = useCreateThread();
    var _a = useState(''), name = _a[0], setName = _a[1];
    var isTitleEmpty = name.trim().length === 0;
    var onSubmit = function (composerBody, event) { return __awaiter(_this, void 0, void 0, function () {
        var thread;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    event.preventDefault();
                    if (isTitleEmpty) {
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, createThread(__assign(__assign({}, composerBody), { metadata: {
                                name: name,
                                caseId: props.caseId,
                                caseName: props.caseName,
                            } }))];
                case 1:
                    thread = _a.sent();
                    if (thread) {
                        props.setSelectedThreadId(thread.id);
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var classes = useStyles();
    return React.createElement("div", { style: { background: '#F0F5F9', padding: '15px 10px', flexGrow: 1 } },
        React.createElement("div", { style: { display: 'flex', alignItems: 'center', marginBottom: 10 } },
            props.showRetour ?
                React.createElement(IconButton, { onClick: props.onBack, size: "small", style: { marginRight: 8 }, title: "Retour \u00E0 la liste des conversations" },
                    React.createElement(ChevronLeft, null))
                : null,
            React.createElement(TextField, { label: "Titre", InputLabelProps: { shrink: true }, size: "small", variant: "outlined", required: true, className: classes.input, name: 'ticket-name', value: name, placeholder: "Titre de la conversation", onChange: function (e) { return setName(e.target.value); }, style: { width: '100%' } })),
        React.createElement("hr", null),
        React.createElement(CustomComposer, { onComposerSubmit: onSubmit, isSubmitDisabled: isTitleEmpty }));
}
function TicketThreadDisplay(props) {
    // const markThreadAsResolved = useMarkThreadAsResolved()
    var thread = props.thread, setSelectedThreadId = props.setSelectedThreadId;
    // Add a useEffect to scroll to the bottom of the thread when it's displayed
    useEffect(function () {
        // Function to scroll to the bottom of the thread
        var scrollToBottom = function () {
            // Find the thread container - it's the element with class 'lb-thread-comments'
            var threadContainer = document.querySelector('.lb-thread-comments');
            if (threadContainer) {
                // Scroll to the bottom
                threadContainer.scrollTop = threadContainer.scrollHeight;
            }
        };
        // Initial scroll after the thread is rendered
        setTimeout(scrollToBottom, 100);
        // Also scroll when new messages are added
        var observer = new MutationObserver(scrollToBottom);
        var threadElement = document.querySelector('.lb-thread');
        if (threadElement) {
            observer.observe(threadElement, {
                childList: true,
                subtree: true,
            });
        }
        return function () {
            observer.disconnect();
        };
    }, [thread.id]);
    // Add a useEffect to ensure the composer shows buttons
    useEffect(function () {
        // Function to make the composer show buttons
        var makeComposerShowButtons = function () {
            var composers = document.querySelectorAll('.lb-thread-composer');
            composers.forEach(function (composer) {
                // Remove the data-collapsed attribute
                composer.removeAttribute('data-collapsed');
                // Check if the footer exists
                var footer = composer.querySelector('.lb-composer-footer');
                if (!footer) {
                    // If the footer doesn't exist, create it
                    var editorContainer = composer.querySelector('.lb-composer-editor-container');
                    if (editorContainer) {
                        // Create the footer
                        var newFooter = document.createElement('div');
                        newFooter.className = 'lb-composer-footer';
                        // Add the editor actions
                        var editorActions = document.createElement('div');
                        editorActions.className = 'lb-composer-editor-actions';
                        editorActions.style.position = 'absolute';
                        editorActions.style.right = '45px';
                        editorActions.style.top = '2px'; // 2px higher as requested
                        editorActions.style.display = 'flex';
                        editorActions.style.gap = '2px';
                        editorActions.style.opacity = '1';
                        editorActions.style.visibility = 'visible';
                        editorActions.innerHTML = "\n              <button type=\"button\" class=\"lb-button lb-composer-editor-action\" data-variant=\"default\" data-size=\"default\" aria-label=\"Mentionner un autre membre de l'\u00E9quipe\" data-state=\"closed\">\n                <svg xmlns=\"http://www.w3.org/2000/svg\" width=\"20\" height=\"20\" viewBox=\"0 0 20 20\" fill=\"none\" stroke=\"#7BA3CC\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\" role=\"presentation\" class=\"lb-icon lb-button-icon\">\n                  <path d=\"M10 13a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z\"></path>\n                  <path d=\"M13 7v3.7c0 1.105.855 2.1 1.9 2.1a2.12 2.12 0 0 0 2.1-2.1V10a7 7 0 1 0-2.8 5.6\"></path>\n                </svg>\n              </button>\n              <button type=\"button\" class=\"lb-button lb-composer-editor-action\" data-variant=\"default\" data-size=\"default\" aria-label=\"Ins\u00E9rer un emoji\" aria-haspopup=\"dialog\" aria-expanded=\"false\" data-state=\"closed\">\n                <svg xmlns=\"http://www.w3.org/2000/svg\" width=\"20\" height=\"20\" viewBox=\"0 0 20 20\" fill=\"none\" stroke=\"#7BA3CC\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\" role=\"presentation\" class=\"lb-icon lb-button-icon\">\n                  <path d=\"M10 17a7 7 0 1 0 0-14 7 7 0 0 0 0 14Z\"></path>\n                  <path d=\"M7.5 11.5S8.25 13 10 13s2.5-1.5 2.5-1.5M8 8h.007\"></path>\n                  <path d=\"M12 8h.007\"></path>\n                  <circle cx=\"8\" cy=\"8\" r=\"0.25\"></circle>\n                  <circle cx=\"12\" cy=\"8\" r=\"0.25\"></circle>\n                </svg>\n              </button>\n            ";
                        // Add the composer actions
                        var composerActions = document.createElement('div');
                        composerActions.className = 'lb-composer-actions';
                        composerActions.innerHTML = "\n              <button type=\"submit\" class=\"lb-button lb-composer-action\" data-variant=\"primary\" data-size=\"default\" aria-label=\"Envoyer\" data-state=\"closed\">\n                <svg xmlns=\"http://www.w3.org/2000/svg\" width=\"20\" height=\"20\" viewBox=\"0 0 20 20\" fill=\"none\" stroke=\"currentColor\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\" role=\"presentation\" class=\"lb-icon\">\n                  <path d=\"m5 16 12-6L5 4l2 6-2 6ZM7 10h10\"></path>\n                </svg>\n              </button>\n            ";
                        // Add the editor actions and composer actions to the footer
                        newFooter.appendChild(editorActions);
                        newFooter.appendChild(composerActions);
                        // Add the footer after the editor container
                        editorContainer.after(newFooter);
                        // Add click event listeners to the buttons
                        var editor_1 = composer.querySelector('.lb-composer-editor');
                        var buttons_1 = newFooter.querySelectorAll('button');
                        buttons_1.forEach(function (button) {
                            button.addEventListener('click', function () {
                                // Focus the editor first
                                if (editor_1) {
                                    editor_1.focus();
                                }
                                // Then click the corresponding button in the real footer
                                setTimeout(function () {
                                    var realFooter = composer.querySelector('.lb-composer-footer:not(:first-child)');
                                    if (realFooter) {
                                        var realButtons = realFooter.querySelectorAll('button');
                                        var index = Array.from(buttons_1).indexOf(button);
                                        if (realButtons[index]) {
                                            realButtons[index].click();
                                        }
                                    }
                                }, 10);
                            });
                        });
                    }
                }
            });
        };
        // Run the function immediately
        makeComposerShowButtons();
        // Set up an interval to run the function every 500ms
        var interval = setInterval(makeComposerShowButtons, 500);
        // Clean up the interval when the component unmounts
        return function () { return clearInterval(interval); };
    }, []);
    return React.createElement("div", { style: { display: 'flex', flexDirection: "column", minHeight: 0, height: '100%', flexGrow: 1 } },
        React.createElement("div", { style: { display: 'flex', alignItems: 'center' } },
            props.showRetour ?
                React.createElement(IconButton, { onClick: function () { return setSelectedThreadId(undefined); }, size: "small", style: { marginRight: 8 }, title: "Retour \u00E0 la liste des conversations" },
                    React.createElement(ChevronLeft, null))
                : null,
            React.createElement("h1", { style: { fontSize: 20, fontWeight: 'bold', margin: 0 } }, thread.metadata.name)),
        React.createElement(Thread, { thread: thread, showAttachments: false, showResolveAction: false, showReactions: false, style: {
                margin: '8px 0',
                // borderRadius: '8px',
                // border: '1px solid #7BA3CC',
                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
                backgroundColor: '#FFFFFF',
                flex: 1,
                overflow: 'hidden',
            } }));
}
function TicketListEntry(props) {
    var thread = props.thread, selectedThreadId = props.selectedThreadId, notifications = props.notifications, setSelectedThreadId = props.setSelectedThreadId;
    var unreadNotifications = useMemo(function () { return filterUnreadNotifications(notifications || []); }, [notifications]);
    var markAsRead = useMarkInboxNotificationAsRead();
    useEffect(function () {
        if (selectedThreadId !== thread.id) {
            return;
        }
        unreadNotifications.forEach(function (n) {
            markAsRead(n.id);
        });
    }, [markAsRead, selectedThreadId, thread.id, unreadNotifications]);
    return React.createElement("div", { key: thread.id, style: {
            background: thread.id === selectedThreadId
                ? palette.secondary.extraLight
                : "",
            padding: '16px 10px',
            cursor: 'pointer',
            transition: 'all 0.2s ease',
        }, onClick: function () {
            setSelectedThreadId(thread.id);
        } },
        React.createElement("div", { style: {
                fontWeight: 'bold',
                fontSize: '16px',
                color: palette.secondary.main,
            } }, thread.metadata.name),
        unreadNotifications.length > 0 ?
            React.createElement("div", { style: {
                    color: palette.error.main,
                    fontSize: '0.9rem',
                    marginTop: '4px',
                } },
                unreadNotifications.length,
                " nouveau ",
                unreadNotifications.length >= 2 ? "x" : "",
                "message",
                unreadNotifications.length >= 2 ? "s" : "") : null);
}
function TicketList(props) {
    var setIsCreateThread = props.setIsCreateThread, threadsUnresolved = props.threadsUnresolved, setSelectedThreadId = props.setSelectedThreadId, selectedThreadId = props.selectedThreadId, threadNotifications = props.threadNotifications, _a = props.isTwoColumn, isTwoColumn = _a === void 0 ? true : _a;
    return React.createElement(React.Fragment, null,
        React.createElement("div", { style: {
                display: 'flex',
                flexDirection: 'column',
                gap: 16,
                minWidth: isTwoColumn ? 250 : '100%',
                maxWidth: isTwoColumn ? 250 : '100%',
                width: '100%',
            } },
            React.createElement("div", { style: {
                    display: 'flex',
                    flexDirection: 'column',
                    overflowY: 'auto',
                    paddingRight: 12,
                } }, threadsUnresolved ? threadsUnresolved.map(function (thread) { return (React.createElement(TicketListEntry, { key: thread.id, thread: thread, selectedThreadId: selectedThreadId, notifications: (threadNotifications === null || threadNotifications === void 0 ? void 0 : threadNotifications[thread.id]) || [], setSelectedThreadId: setSelectedThreadId })); }) : null),
            React.createElement(Button, { variant: "contained", color: "primary", startIcon: React.createElement(AddCircleOutline, null), onClick: function () { return setIsCreateThread(true); }, style: { marginRight: 12 } }, "Nouvelle conversation")));
}
function TicketDialogInside(props) {
    var _a;
    var threads = useThreads().threads;
    var threadsWithNotifications = useLiveblocksNotifications().threadsWithNotifications;
    // console.log("TicketDialogInside inboxNotifications", notifications)
    var _b = useState(false), isCreateThread = _b[0], setIsCreateThread = _b[1];
    var _c = useState(undefined), selectedThreadId = _c[0], setSelectedThreadId = _c[1];
    // When setting isCreateThread to true, also clear selectedThreadId
    var setIsCreateThreadProxy = useCallback(function (value) {
        setIsCreateThread(value);
        if (value) {
            setSelectedThreadId(undefined);
        }
    }, []);
    var setSelectedThreadIdProxy = useCallback(function (id) {
        setSelectedThreadId(id);
        setIsCreateThread(false);
    }, []);
    var selectedThread = selectedThreadId ? ((_a = threads.filter(function (thread) { return thread.id === selectedThreadId; })) === null || _a === void 0 ? void 0 : _a[0]) || undefined : undefined;
    // // Group notifications by thread ID
    // const threadsWithNotifications = useMemo(() => notifications?.reduce((res, data) => {
    //   const threadId = (data as InboxNotificationThreadData).threadId;
    //
    //   // Skip if no thread ID
    //   if (!threadId) {
    //     return res;
    //   }
    //
    //   // Add the notification to the result
    //   if (!res[threadId]) {
    //     res[threadId] = [];
    //   }
    //   res[threadId].push(data);
    //   return res;
    // }, {} as Record<string, InboxNotificationData[]>), [notifications]);
    var isTwoColumn = props.dialogWidth > 605;
    // Check URL for conversation thread ID
    useEffect(function () {
        var queryParams = new URLSearchParams(window.location.search);
        var conversation_thread_id = queryParams.get('conversation_thread_id');
        if (conversation_thread_id) {
            setSelectedThreadIdProxy(conversation_thread_id);
        }
    }, [setSelectedThreadIdProxy]);
    var threadsUnresolved = threads === null || threads === void 0 ? void 0 : threads.filter(function (thread) { return !thread.resolved; }).sort(function (a, b) {
        if (a.updatedAt > b.updatedAt) {
            return -1;
        }
        if (a.updatedAt < b.updatedAt) {
            return 1;
        }
        return 0;
    });
    if (isTwoColumn) {
        return React.createElement("div", { style: { display: "flex", width: '100%', height: '100%' } },
            React.createElement(TicketList, { threadsUnresolved: threadsUnresolved, setSelectedThreadId: setSelectedThreadIdProxy, setIsCreateThread: setIsCreateThreadProxy, selectedThreadId: selectedThreadId, threadNotifications: threadsWithNotifications, isTwoColumn: true }),
            selectedThread && !isCreateThread ?
                React.createElement(TicketThreadDisplay, { showRetour: false, thread: selectedThread, setSelectedThreadId: setSelectedThreadIdProxy }) : null,
            isCreateThread ?
                React.createElement(TicketCreateThread, { showRetour: false, caseId: props.caseId, caseName: props.caseName, onBack: function () { return setIsCreateThreadProxy(false); }, setSelectedThreadId: setSelectedThreadIdProxy }) : null,
            !selectedThread && !isCreateThread ? React.createElement("div", { style: {
                    flexGrow: 1,
                    textAlign: "center",
                    height: "100%",
                    verticalAlign: "middle",
                    fontSize: "20px",
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    alignItems: "center",
                } }, "Aucune conversation s\u00E9lectionn\u00E9e") : null);
    }
    if (selectedThread) {
        return React.createElement(TicketThreadDisplay, { showRetour: true, thread: selectedThread, setSelectedThreadId: setSelectedThreadIdProxy });
    }
    if (isCreateThread) {
        return React.createElement(TicketCreateThread, { showRetour: true, caseId: props.caseId, caseName: props.caseName, onBack: function () { return setIsCreateThreadProxy(false); }, setSelectedThreadId: setSelectedThreadId });
    }
    return React.createElement(TicketList, { threadsUnresolved: threadsUnresolved, setSelectedThreadId: setSelectedThreadId, setIsCreateThread: setIsCreateThreadProxy, selectedThreadId: selectedThreadId, threadNotifications: threadsWithNotifications, isTwoColumn: false });
}
function TicketDialogWrapper(props) {
    var _a = useRoomNotificationSettings(), settings = _a[0].settings, updateSettings = _a[1];
    // { threads: "replies_and_mentions" }
    console.log(settings);
    // No longer receive thread notifications in this room
    //   updateSettings({
    //     threads: "none",
    //   });
    var _b = useState(false), isDragging = _b[0], setIsDragging = _b[1];
    var _c = useState({ x: 0, y: 0 }), position = _c[0], setPosition = _c[1];
    var _d = useState({ x: 0, y: 0 }), offset = _d[0], setOffset = _d[1];
    var _e = useState(720), width = _e[0], setWidth = _e[1];
    var _f = useState(500), height = _f[0], setHeight = _f[1];
    var startDrag = function (e) {
        setIsDragging(true);
        setOffset({
            x: e.clientX - position.x,
            y: e.clientY - position.y,
        });
    };
    var drag = function (e) {
        if (isDragging) {
            setPosition({
                x: e.clientX - offset.x,
                y: e.clientY - offset.y,
            });
        }
    };
    var stopDrag = function () {
        setIsDragging(false);
    };
    return React.createElement("div", { style: {
            position: 'fixed',
            top: "".concat(position.y, "px"),
            left: "".concat(position.x, "px"),
            background: 'white',
            width: "".concat(width, "px"),
            borderRadius: '10px',
            boxShadow: '0px 4px 10px rgba(0,0,0,0.1)',
            zIndex: 1100,
            padding: '20px',
            // cursor: isDragging ? 'grabbing' : 'grab',
            userSelect: 'none',
            height: "".concat(height, "px"),
            // maxHeight: 500,
            display: 'flex',
            cursor: isDragging ? "grabbing" : "default",
            flexDirection: 'column',
        }, onMouseDown: startDrag, onMouseMove: function (e) {
            drag(e);
        }, onMouseUp: function () {
            stopDrag();
        }, onMouseLeave: function () {
            stopDrag();
        } },
        React.createElement(Resizable, { height: height, width: width, minConstraints: [320, 400], onResize: function (event, data) {
                stopDrag();
                setHeight(data.size.height);
                setWidth(data.size.width);
            } },
            React.createElement(React.Fragment, null,
                React.createElement("div", { style: { display: 'flex', justifyContent: 'space-between', marginBottom: 20, flexShrink: 0 } },
                    React.createElement(Typography, { id: "transition-modal-title", variant: "h2", component: "h2" }, "Conversations"),
                    React.createElement(IconButton, { onClick: props.onClose, "aria-label": "close", size: "small" },
                        React.createElement(Close, null))),
                React.createElement("div", { style: {
                        display: 'flex',
                        flexDirection: 'column',
                        flex: 1,
                        minHeight: 0,
                    } },
                    React.createElement(TicketDialogInside, { dialogWidth: width, caseId: props.caseId, caseName: props.caseName })))));
}
function LiveblockNotificationsManager() {
    // const { inboxNotifications } = useInboxNotifications()
    // const { setNotifications } = useLiveblocksNotifications()
    //
    // useEffect(() => {
    //   console.log('NotificationBell, ', inboxNotifications)
    //   setNotifications(inboxNotifications)
    // }, [inboxNotifications, setNotifications])
    return null;
}
export function CaseConversationDialog(props) {
    var orgId = props.orgId;
    var caseIdUnique = props.caseIdUnique;
    return (React.createElement(ThemeProvider, { theme: localTheme },
        React.createElement(Room, { roomId: "org:".concat(orgId, ":").concat(caseIdUnique) },
            React.createElement(LiveblockNotificationsManager, null),
            props.open ? React.createElement(React.Fragment, null,
                React.createElement(TicketDialogWrapper, { onClose: props.onClose, caseId: props.caseId, caseName: props.caseName })) : React.createElement("div", null))));
}
