import { createMuiTheme, makeStyles } from '@material-ui/core/styles';

export const palette = {
  primary: {
    extraLight: '#FAF0F8',
    main100: '#F0D1EA',
    main200: '#E1A3D2',
    main: '#9E3386',
    main700: '#6C235B',
  },
  secondary: {
    extraLight: '#F0F5F9',
    light: '#D3E0EE',
    main200: '#A7C2DD',
    main300: '#7BA3CC',
    main400: '#4F85BA',
    main500: '#3F70A2',
    main600: '#2E5276',
    main: '#213B55',
    main800: '#17293B',
    main000: '#0B141D',
    contrastText: '#fff',
  },
  error: {
    light: '#F0F5F9',
    main: '#F54952',
  },
  success: {
    main: '#22A8AA',
  },
  info: {
    light: '#ECF6FE',
    main: '#0D7AD3',
  },
  warning: {
    main: '#F5CA66',
  },
  divider: '#E6E0E9',
};

export const localTheme = createMuiTheme({
  palette,
  typography: {
    fontFamily: [
      '"IBM Plex Sans Condensed"',
      'Arial',
      'sans-serif',
    ].join(','),
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    allVariants: {
      fontSize: 14,
      color: palette.secondary.main,
    },
    h2: {
      fontSize: '24px',
      fontWeight: 'bold',
    },
    button: {
      textTransform: 'none',
      fontWeight: 'normal',
    },
  },
  overrides: {
    MuiSwitch: {
      root: {},
      colorPrimary: {
        // This targets only primary color switches in unchecked state
        '&:not($checked)': {
          // Styles for the thumb in unchecked state
          '& $thumb': {
            backgroundColor: palette.secondary.main200,
          },
          // The track is a sibling element to the root
          '& + $track': {
            backgroundColor: palette.secondary.light,
            opacity: 0.7,
          },
        },
      },
      colorSecondary: {
        // Leave this empty or define styles for secondary color switches
      },
      checked: {}, // Reference
      track: {},   // Reference
      thumb: {},   // Reference
    },
    MuiCssBaseline: {
      '@global': {
        body: {
          '-webkit-font-smoothing': 'antialiased',
          '-moz-osx-font-smoothing': 'grayscale',
        },
      },
    },
    MuiButton: {
      label: {
        fontFamily: '"IBM Plex Sans Condensed" !important', // Fix for components that don't inherit font automatically
      },
    },
    MuiTableCell: {
      root: {
        borderBottom: 'none',
        borderRight: `1px solid ${palette.secondary.main200}`,
      },
      head: {
        fontWeight: 'bold',
        '&:first-child': {
          borderLeft: `1px solid ${palette.secondary.main200}`,
        },
      },
      body: {
        '&:first-child': {
          borderLeft: `1px solid ${palette.secondary.main200}`,
        },
      },
    },
    MuiFormHelperText: {
      root: {
        fontSize: 12,
        color: palette.secondary.main300,
      },
    },
    MuiInput: {
      root: {
        color: palette.secondary.main500,
        '&:hover:not($disabled):not($focused):not($error)': {
          color: palette.secondary.main500,
        },
        '&$focused': {
          color: palette.secondary.main500,
        },
      },
      underline: {
        '&:before': {
          borderBottomColor: palette.secondary.main300,
        },
        '&:hover:not($disabled):not($focused):not($error):before': {
          borderBottomColor: palette.secondary.main300,
        },
        '&:after': {
          borderBottomColor: palette.secondary.main300,
        },
      },
    },
    MuiInputLabel: {
      root: {
        fontFamily: '"IBM Plex Sans Condensed" !important', // Fix for components that don't inherit font automatically
      },
      shrink: {
        color: palette.secondary.main300,
        fontSize: 13,
        transform: 'translate(14px, -7px) !important',
      },
    },
    MuiInputAdornment: {
      root: {
        '& .MuiTypography-root': {
          color: `${palette.secondary.main500} !important`,
        },
        '& .MuiTypography-colorTextSecondary': {
          color: `${palette.secondary.main500} !important`,
        },
      },
    },
    MuiFormLabel: {
      asterisk: {
        color: palette.error.main,
      },
    },
    MuiOutlinedInput: {
      root: {
        '& fieldset': {
          borderColor: palette.secondary.main300,
        },
      },
      notchedOutline: {
        '& legend': {
          fontSize: '0.8rem',
        },
      },
    },
    MuiInputBase: {
      root: {
        '& textarea, & input': { // override bootswatch css underline on inputs
          WebkitBoxShadow: 'none !important',
          boxShadow: 'none !important',
          transition: 'none',
          backgroundColor: '#FFFFFF',
        },
        '& textarea::placeholder, & input::placeholder': {
          color: palette.secondary.main300,
          opacity: 1,
        },
      },
      input: {
        color: palette.secondary.main500,
        backgroundColor: '#FFFFFF',
        '&[type="date"]': {
          '&::-webkit-calendar-picker-indicator': {
            filter: `
                        brightness(0)
                        saturate(100%)
                        invert(62%)
                        sepia(68%)
                        saturate(216%)
                        hue-rotate(169deg)
                        brightness(88%)
                        contrast(91%)
                        `,
            display: 'block',
            cursor: 'pointer',
          },
        },
      },
    },
    MuiSelect: {
      icon: {
        color: palette.secondary.main300,
        '&$outlined': {
          color: palette.secondary.main300,
        },
        '&$disabled': {
          color: palette.secondary.main300,
        },
      },
    },
    MuiTab: {
      textColorPrimary: {
        color: palette.secondary.main,
      },
      root: {
        color: palette.secondary.main,
        backgroundColor: palette.secondary.extraLight,
        borderBottom: `1px solid ${palette.divider}`,
        "&$selected": {
          color: palette.primary.main,
          borderBottom: `1px solid ${palette.primary.main}`,
          backgroundColor: palette.primary.extraLight,
        },
        '&:focus': {
          outline: 'none',
        },
        minHeight: '48px',
        maxHeight: '48px',
      },
      wrapper: {
        minHeight: '48px',
        maxHeight: '48px',
      },
    },
    MuiTabs: {
      indicator: {
        display: 'none',
      },
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: palette.secondary.main500,
        color: palette.secondary.contrastText,
        fontSize: '0.875rem',
        fontWeight: 400,
      },
      arrow: {
        color: palette.secondary.main500,
      },
    },
  },
});

export const useStyles = makeStyles((localTheme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: localTheme.palette.background.paper,
    border: 'none',
    boxShadow: localTheme.shadows[5],
    padding: localTheme.spacing(2, 4, 3),
    marginTop: '5vh',
    maxHeight: '90vh',
    minWidth: '80vw',
    maxWidth: '95vw',
  },
  appBar: {
    border: 0,
    borderBottom: `1px solid ${localTheme.palette.divider}`,
    minHeight: '48px',
    maxHeight: '48px',
  },
  tabWrapper: {
    '& .MuiTab-wrapper': {
      paddingLeft: 12,
      paddingRight: 12,
    },
  },
  tabLabel: {
    display: 'flex',
    alignItems: 'center',
    textTransform: 'none',
    maxWidth: '200px',
    minWidth: '150px',
    fontWeight: 'normal',
    width: '100%',
    justifyContent: 'space-between',
  },
  editingTab: {
    backgroundColor: localTheme.palette.primary.main200,
    '& .MuiTab-wrapper': {
      paddingLeft: 12,
      paddingRight: 12,
    },
  },
  editTabButton: {
    width: 20,
    height: 20,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: localTheme.palette.action.hover,
    },
  },
  deleteTabButton: {
    marginBottom: "3px",
    marginLeft: "6px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: localTheme.palette.action.hover,
    },
  },
  closeButton: {
    marginLeft: 'auto',
  },
  addButtonTab: {
    maxWidth: '100px!important',
    minWidth: '100px!important',
    border: `1px solid ${localTheme.palette.divider}`,
  },
  input: {
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
  table: {
    minWidth: 650,
    width: "100%",
    '@media (max-width: 1150px)': {
      '& td:last-child, & th:last-child': {
        display: 'none',
      },
    },
  },
  resultsTable: {
    maxWidth: 400,
    minWidth: 380,
  },
  root: {
    width: '100%',
    marginTop: "20px",
  },
  container: {
    maxHeight: "calc(90vh - 480px)",
  },
  ResultsTableCell:{
    textAlign: "left",
  },
  boldTableRow: {
    '& > *': {
      fontWeight: 900,
    },
  },
  resultsTableTotalRow: {
    '& > *': {
      fontSize: 16,
    },
  },
  footerResultsTableCell:{
    fontSize: "16px!important",
  },
  commentField: {
    flex: 1,
  },
  centerContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  tableContainerGrid: {
    marginTop: 20,
    maxWidth: 400,
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    backgroundColor: palette.secondary.extraLight,
    height: 60,
    alignItems: 'center',
    width: '100%',
  },
}));
