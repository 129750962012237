import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { logout } from "../../actions/auth";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ListIcon from '@material-ui/icons/List';
import QuantumLogo from "../../img/logo_white_baseline.svg"
import { NotificationsLiveblocks } from "./InAppNotificationsLiveblocks";


const Header = props => {

  const { auth, isCaseList, logout, cases } = props;

  const authLinks = (
    <ul className="navbar-nav ml-auto mt-2 mt-lg-0">
      <li className="nav-item">
        <NotificationsLiveblocks cases={cases.cases}/>
      </li>
      {(!isCaseList) &&
      <li className="nav-item pt-2"> {/* pt-2 should be arranged on the whole menu instead of on each <li> */}
        <Link
          to="/"
          className="btn btn-primary btn-sm mr-2"
          title="Retour à la liste des dossiers"
        >
          <ListIcon/> Mes dossiers
        </Link>
      </li>
      }
      <li className="nav-item pt-2"> {/* pt-2 should be arranged on the whole menu instead of on each <li> */}
        <Link
          to="/compte"
          className="btn btn-primary btn-sm mr-2"
          title="Gestion du compte utilisateur"
        >
          <AccountCircleIcon/> Mon compte
        </Link>
      </li>
      <li className="nav-item pt-2"> {/* pt-2 should be arranged on the whole menu instead of on each <li> */}
        <button
          type="button"
          onClick={logout}
          className="btn btn-primary btn-sm"
          title="Déconnexion"
        >
          <PowerSettingsNewIcon/>
        </button>
      </li>
    </ul>
  );

  const guestLinks = (
    <ul className="navbar-nav ml-auto mt-2 mt-lg-0">
      <li className="nav-item">
        <a href="https://buy.stripe.com/28oeYWcYTgJq0N29AH" className="nav-link">
                    Créer un compte
        </a>
      </li>
      <li className="nav-item">
        <Link to="/identification" className="nav-link">
                    S'identifier
        </Link>
      </li>
    </ul>
  );

  return (
    <nav className="pl-0 navbar fixed-top navbar-expand-sm navbar-dark header">
      <div className="pl-1 container-fluid">
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarTogglerDemo01"
          aria-controls="navbarTogglerDemo01"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarTogglerDemo01">
          <a className="navbar-brand pl-2" href="/">
            <img src={QuantumLogo} style={{ height: 40 }} alt="Quantum"/>
          </a>
        </div>
        {auth.isAuthenticated ? authLinks : guestLinks}
      </div>
    </nav>
  );
}

const mapStateToProps = state => ({
  auth: state.auth,
  cases: state.cases,
  isCaseList: state.cases.isCaseList,
});

export default connect(mapStateToProps, { logout })(Header);
