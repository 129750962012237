var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { LiveblocksProvider } from "@liveblocks/react/suspense";
import React from "react";
import { LiveblocksUIConfig } from "@liveblocks/react-ui";
export function LiveblocksProviderImpl(_a) {
    var _this = this;
    var children = _a.children, auth = _a.auth;
    return (React.createElement(LiveblocksUIConfig, { overrides: {
            locale: 'fr',
            COMPOSER_PLACEHOLDER: 'Écrire un commentaire...',
            COMPOSER_INSERT_EMOJI: 'Insérer un emoji',
            COMPOSER_INSERT_MENTION: "Mentionner un autre membre de l'équipe",
            COMPOSER_SEND: 'Envoyer',
            THREAD_RESOLVE: 'Archiver',
            THREAD_NEW_INDICATOR: 'Nouveau',
            THREAD_NEW_INDICATOR_DESCRIPTION: 'Vous avez des messages non lus',
            THREAD_COMPOSER_PLACEHOLDER: 'Répondre à cette conversation',
            THREAD_COMPOSER_SEND: 'Envoyer',
            COMMENT_ADD_REACTION: 'Ajouter une réaction',
            COMMENT_MORE: 'Plus',
            COMMENT_EDIT: 'Modifier le commentaire',
            COMMENT_DELETE: 'Supprimer le commentaire',
            COMMENT_EDITED: '(modifié)',
            // Custom theme properties are not supported in this version
        } },
        React.createElement(LiveblocksProvider, { authEndpoint: function (room) { return __awaiter(_this, void 0, void 0, function () {
                var response;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, fetch("/api/liveblocks-auth", {
                                method: "POST",
                                headers: {
                                    Authorization: "Token ".concat(auth.token),
                                    "Content-Type": "application/json",
                                },
                                // Don't forget to pass `room` down. Note that it
                                // can be undefined when using Notifications.
                                body: JSON.stringify({ room: room }),
                            })];
                        case 1:
                            response = _a.sent();
                            return [4 /*yield*/, response.json()];
                        case 2: return [2 /*return*/, _a.sent()];
                    }
                });
            }); }, resolveUsers: function (_a) { return __awaiter(_this, [_a], void 0, function (_b) {
                var result, users;
                var userIds = _b.userIds;
                return __generator(this, function (_c) {
                    switch (_c.label) {
                        case 0: return [4 /*yield*/, fetch('/api/my-teams/users', {
                                method: 'POST',
                                headers: {
                                    Authorization: "Token ".concat(auth.token),
                                    "Content-Type": "application/json",
                                },
                                // body: JSON.stringify({ userIds }),
                                body: JSON.stringify({ userIdsUnique: userIds }),
                            })];
                        case 1:
                            result = _c.sent();
                            return [4 /*yield*/, result.json()];
                        case 2:
                            users = _c.sent();
                            return [2 /*return*/, users.map(function (userData) { return ({
                                    name: userData.name,
                                    // avatar: '/public/images/account_circle.png',
                                    // avatar: "https://randomuser.me/api/portraits/men/22.jpg",
                                }); })];
                    }
                });
            }); }, resolveMentionSuggestions: function (_a) { return __awaiter(_this, [_a], void 0, function (_b) {
                var result, users;
                var text = _b.text, roomId = _b.roomId;
                return __generator(this, function (_c) {
                    switch (_c.label) {
                        case 0: return [4 /*yield*/, fetch('/api/my-teams/users', {
                                method: 'POST',
                                headers: {
                                    Authorization: "Token ".concat(auth.token),
                                    "Content-Type": "application/json",
                                },
                                body: JSON.stringify({ roomId: roomId }),
                            })];
                        case 1:
                            result = _c.sent();
                            return [4 /*yield*/, result.json()
                                // If there's a query, filter for the relevant users
                            ];
                        case 2:
                            users = _c.sent();
                            // If there's a query, filter for the relevant users
                            if (text) {
                                // Filter any way you'd like, e.g. checking if the name matches
                                users = users.filter(function (user) { return [user.email, user.name].filter(function (item) { return item.includes(text); }).length > 0; });
                            }
                            // console.log('resolveMentionSuggestions', users)
                            // Return the filtered `userIds`
                            return [2 /*return*/, users.map(function (user) { return String(user.id_unique); })];
                    }
                });
            }); } }, children)));
}
