import React, { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useInboxNotifications } from "@liveblocks/react";
import { filterUnreadNotifications } from "../cases/physical_injury/conversation/liveblocksUtil";
var NotificationContext = createContext(null);
export var NotificationProvider = function (_a) {
    var children = _a.children;
    var inboxNotifications = useInboxNotifications().inboxNotifications;
    var _b = useState([]), notifications = _b[0], setNotifications = _b[1];
    var unreadNotifications = useMemo(function () { return filterUnreadNotifications(notifications); }, [notifications]);
    // Group notifications by thread ID
    var threadsWithNotifications = useMemo(function () { return notifications.reduce(function (res, data) {
        var threadId = data.threadId;
        // Skip if no thread ID
        if (!threadId) {
            return res;
        }
        // Add the notification to the result
        if (!res[threadId]) {
            res[threadId] = [];
        }
        res[threadId].push(data);
        return res;
    }, {}); }, [notifications]);
    useEffect(function () {
        setNotifications(inboxNotifications || []);
    }, [inboxNotifications]);
    return (React.createElement(NotificationContext.Provider, { value: { notifications: notifications, threadsWithNotifications: threadsWithNotifications, unreadNotifications: unreadNotifications } }, children));
};
export var useLiveblocksNotifications = function () {
    var context = useContext(NotificationContext);
    if (!context) {
        throw new Error("useLiveblocksNotifications must be used within a NotificationProvider");
    }
    return context;
};
